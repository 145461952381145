<!--
 * @Descripttion: 全景预览
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-27 11:49:03
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-27 13:47:30
-->
<template>
    <div class="panoramaView">
        <iframe :src="link" id="qt" scrolling="no" frameborder="0" class="content">
        </iframe>
    </div>
</template>

<script>
export default {
    data () {
        return {
            link: ''
        }
    },
    mounted () {
        this.link = localStorage.getItem('link')
    },
    methods: {

    },
}
</script>

<style lang='scss'>
.panoramaView{
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    position: relative;
    .content{
        position:absolute;
        top:0;
        left: 0px;
        width: 100%;
        height: 100%;
    }
}
</style>
